import { DbRichTrack } from 'generated/database.types'
import { RichRecordT } from 'generated/types'
import { useEffect } from 'react'
import { createSelector, OutputSelector } from 'reselect'
import { ReduxState } from 'shared/reducers/root.reducer'

export const getRichRecordsSelector = (
  state: ReduxState,
): Array<DbRichTrack> | undefined => state.data.richRecords

export type RichDataReturn = Array<{ day: number; recs: Array<DbRichTrack> }>

export const getRichDataSelector = createSelector(
  getRichRecordsSelector,
  (records) => {
    const days = [0, 1, 2, 3, 4, 5, 6]
    const res: RichDataReturn = days.map((day) => ({
      day,
      recs: (records || []).filter(
        (record) =>
          new Date(record.timestamp).getDay() === day &&
          new Date(record.timestamp).getHours() >= 10 &&
          new Date(record.timestamp).getHours() < 23,
      ),
    }))
    return res
  },
)

export const getCurrentRichDataSelector: OutputSelector<
  ReduxState,
  RichRecordT[],
  (res: DbRichTrack[]) => RichRecordT[]
> = createSelector(
  getRichRecordsSelector,
  (richRecords): Array<RichRecordT> => {
    return richRecords
  },
)
