import {
  GetRequest,
  RestMethod,
  PostRequest,
} from 'frr-redux/lib/frr/api.types'
import { Day, DbCoffee, DbRichTrack, DbTrack, Ticket } from './database.types'

export enum Endpoints {
  StoreCount = 'store_count',

  // GetRecords = '/get_records',
  // GetPrevRecords = '/get_prev_records',
  GetRichRecords = '/data',

  GetCoffee = '/get_coffee',
  InsertCoffee = '/insert_coffee',

  GetRing = '/get_ring',
  ValidateRing = '/validate_ring',

  PostSolution = '/post_solution',
  PostWeeklySolution = '/post_weekly_solution',
  PostEmail = '/post_email',

  PostInitialPasscode = '/post_initial_passcode',

  GetWeeklyRiddle = '/get_weekly_riddle',

  // Sauna Waitinglist
  GetToday = '/get_today',
  GetTickets = '/get_tickets',

  AddTicket = '/add_ticket',
  InvalidateTicket = '/invalidate_ticket',
  ToggleWaiting = '/toggle_waiting',
}

export type API = {
  // GET
  // [Endpoints.GetRecords]: GetRequest<{
  //   query: { startDate: number; endDate: number }
  //   response: Array<DbTrack>
  // }>

  // [Endpoints.GetPrevRecords]: GetRequest<{
  //   query: { startDate: number; endDate: number }
  //   response: Array<DbTrack>
  // }>

  [Endpoints.ValidateRing]: PostRequest<{
    json: {
      query: string
    }
    response: {}
  }>

  [Endpoints.GetRing]: GetRequest<{
    response: {
      value: boolean
    }
  }>

  [Endpoints.GetRichRecords]: GetRequest<{
    query: { startDate: number; endDate: number }
    response: Array<DbRichTrack>
  }>

  [Endpoints.GetCoffee]: GetRequest<{
    query: {}
    response: Array<DbCoffee>
  }>

  [Endpoints.InsertCoffee]: PostRequest<{
    json: {}
    response: DbCoffee
  }>

  // POST
  [Endpoints.StoreCount]: PostRequest<{
    json: DbTrack
    response: {}
  }>

  [Endpoints.PostSolution]: PostRequest<{
    json: { answer: string; email: string }
    response: { correct: boolean; token: string }
  }>
  [Endpoints.PostWeeklySolution]: PostRequest<{
    json: { answer: string }
    response: { correct: boolean; token: string }
  }>
  [Endpoints.PostInitialPasscode]: PostRequest<{
    json: { answer: string }
    response: { correct: boolean; url: string }
  }>

  [Endpoints.PostEmail]: PostRequest<{
    json: { answer: string; email: string; correct: boolean; hint: boolean }
    response: {}
  }>

  [Endpoints.GetWeeklyRiddle]: PostRequest<{
    json: { date: string }
    response: {
      text: string
      imageUrl: string
    }
  }>

  // Sauna Waitinglist

  [Endpoints.GetToday]: GetRequest<{
    query: { date: number }
    response: {
      today: Day
    }
  }>

  [Endpoints.GetTickets]: GetRequest<{
    query: { date: number }
    response: {
      todaysTickets: Array<Ticket>
    }
  }>

  [Endpoints.AddTicket]: PostRequest<{
    json: { ticket: Ticket }
    response: {}
  }>

  [Endpoints.InvalidateTicket]: PostRequest<{
    json: { ticket: Ticket }
    response: {}
  }>

  [Endpoints.ToggleWaiting]: PostRequest<{
    json: { date: number }
    response: {}
  }>
}

export const mapEndpointToMethod: {
  [k in Endpoints]: API[k]['method']
} = {
  // [Endpoints.GetRecords]: RestMethod.GET,
  // [Endpoints.GetPrevRecords]: RestMethod.GET,
  [Endpoints.GetRichRecords]: RestMethod.GET,
  [Endpoints.StoreCount]: RestMethod.POST,
  [Endpoints.GetCoffee]: RestMethod.GET,
  [Endpoints.InsertCoffee]: RestMethod.POST,
  [Endpoints.GetRing]: RestMethod.GET,

  [Endpoints.ValidateRing]: RestMethod.POST,
  [Endpoints.PostSolution]: RestMethod.POST,
  [Endpoints.PostWeeklySolution]: RestMethod.POST,
  [Endpoints.PostEmail]: RestMethod.POST,
  [Endpoints.PostInitialPasscode]: RestMethod.POST,
  [Endpoints.GetWeeklyRiddle]: RestMethod.POST,

  // Sauna Waitinglist

  [Endpoints.GetToday]: RestMethod.GET,
  [Endpoints.GetTickets]: RestMethod.GET,

  [Endpoints.AddTicket]: RestMethod.POST,
  [Endpoints.InvalidateTicket]: RestMethod.POST,
  [Endpoints.ToggleWaiting]: RestMethod.POST,
}
