import { Chart } from 'components/Chart'
import { useIsMobile } from 'components/Scaffold'
import { DbRichTrack } from 'generated/database.types'
import React, { useEffect } from 'react'
import 'react-dropdown/style.css'
import ReactGA from 'react-ga'
import 'react-notifications-component/dist/theme.css'
import styled from 'styled-components'
import logo from '../assets/logo.png'
import {
  BlobCircle,
  Button,
  ButtonLabel,
  ButtonWrapper,
  HeadingContainer,
  HomeWrapper,
  LegendCircle,
  LegendRow,
  LegendWrapper,
  LogoContainer,
  Stats,
  StatsEntry,
} from '../components/styledcomponents'
import { weekDays } from './weekdays'
import { AiOutlineMail } from 'react-icons/ai'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'components/constants'

ReactGA.initialize('UA-127896936-6')

export const timestampToMinute = (timestamp: number) =>
  new Date(timestamp).getHours() * 60 + new Date(timestamp).getMinutes()

export const Home = (props: {
  todaysData: Array<DbRichTrack>
  selectedDay: number
  setSelectedDay: (v: number) => void
  freeSpots: number
  waitingList: number
  lakeTemp: number
  busierThanUsual: number
}) => {
  const {
    waitingList,
    freeSpots,
    lakeTemp,
    todaysData,
    selectedDay,
    setSelectedDay,
  } = props

  const isMobile = useIsMobile()

  const sortedWeekDays = weekDays
    .slice(new Date().getDay())
    .concat(weekDays.slice(0, new Date().getDay()))

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <HomeWrapper>
      <HeadingContainer>
        <LogoContainer>
          <img
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              objectFit: 'contain',
            }}
            src={logo}
            alt=""
          />
        </LogoContainer>
        <div>
          <h1 style={{ flex: 1, margin: '16px 0px' }}>Sauna Guru</h1>
          <h1 style={{ flex: 1, margin: '16px 0px' }}>Zürich Enge</h1>
        </div>
      </HeadingContainer>

      <FeedbackButton>
        <a
          style={{
            color: 'black',
            textDecoration: 'none',
          }}
          href="mailto:romanmakesthings@gmail.com?subject=Feedback Saunaguru"
        >
          Feedback?
        </a>
        <AiOutlineMail />
      </FeedbackButton>

      <Stats>
        <StatsEntry>
          <h3>Opening times</h3>
          <h3>Daily 10:00 - 23:00</h3>
        </StatsEntry>
        {/* <StatsEntry>
          <h3>Lake temperature</h3>
          <h3>{lakeTemp === 0 ? '...' : lakeTemp} °C</h3>
        </StatsEntry>
        <StatsEntry>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h3>Free spots now</h3>
            {new Date().getHours() >= 10 ? <BlobCircle /> : <div />}
          </div>
          {new Date().getHours() < 10 ? (
            <h3 style={{ opacity: 0.4 }}> Opening at 10 am</h3>
          ) : (
            <h3>{freeSpots}</h3>
          )}
        </StatsEntry> */}
        {waitingList > 0 && (
          <StatsEntry>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>Current waiting list</h3>
              {new Date().getHours() >= 10 ? <BlobCircle /> : <div />}
            </div>
            <h3>{waitingList}</h3>
          </StatsEntry>
        )}
      </Stats>
      <ButtonWrapper>
        {sortedWeekDays.map((day, idx) => (
          <Button
            key={idx}
            onClick={() => setSelectedDay(day.value)}
            style={{
              backgroundColor:
                selectedDay === day.value ? 'white' : 'lightblue',
              borderColor: selectedDay === day.value ? 'red' : 'black',
            }}
          >
            <ButtonLabel>
              {isMobile ? day.label.slice(0, 3) : day.label}
            </ButtonLabel>
            {!isMobile && <ButtonLabel>{day.genders}</ButtonLabel>}
          </Button>
        ))}
      </ButtonWrapper>
      <LegendWrapper style={{ marginBottom: 16 }}>
        {new Date().getDay() === selectedDay && (
          <LegendRow>
            <LegendCircle style={{ backgroundColor: '#DEF0FD' }} />
            <h4 style={{ padding: 0, margin: 4 }}>Todays visitors (live)</h4>
          </LegendRow>
        )}
        <LegendRow>
          <LegendCircle style={{ backgroundColor: 'rgb(53, 162, 235)' }} />
          <h4 style={{ padding: 0, margin: 4 }}>
            Expected visitors (updated every 10min)
          </h4>
        </LegendRow>
        <LegendRow>
          <LegendCircle
            style={{ backgroundColor: 'rgba(250, 126, 126, 0.5)' }}
          />
          <h4 style={{ padding: 0, margin: 4 }}>Waiting list</h4>
        </LegendRow>
      </LegendWrapper>

      <Chart
        todaysData={todaysData}
        isToday={new Date().getDay() === selectedDay}
      />
    </HomeWrapper>
  )
}

const FeedbackButton = styled.button`
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  margin-top: 32px;
  position: absolute;
  justify-content: center;
  border: none;

  ${DESKTOP_STYLE} {
    flex: 0 0 40px;
    width: 140px;
    top: 0px;
    right: 40px;
  }

  ${MOBILE_STYLE} {
    flex: 0 0 140px;
    height: 40px;
    top: 0px;
    right: 16px;
  }
`
