export const weekDays = [
  {
    value: 0,
    label: 'Sunday',
    openTime: '10 am',
    closeTime: '23 pm',
    genders: '👩👨',
  },
  {
    value: 1,
    label: 'Monday',
    openTime: '10 am',
    closeTime: '23 pm',
    genders: '👩 ',
  },
  {
    value: 2,
    label: 'Tuesday',
    openTime: '10 am',
    closeTime: '23 pm',
    genders: '👩  | 👨',
  },
  {
    value: 3,
    label: 'Wednesday',
    openTime: '10 am',
    closeTime: '23 pm',
    genders: '👩  | 👨',
  },
  {
    value: 4,
    label: 'Thursday',
    openTime: '10 am',
    closeTime: '23 pm',
    genders: '👩  | 👨',
  },
  {
    value: 5,
    label: 'Friday',
    openTime: '10 am',
    closeTime: '23 pm',
    genders: '👩  | 👨',
  },
  {
    value: 6,
    label: 'Saturday',
    openTime: '10 am',
    closeTime: '23 pm',
    genders: '👩 |  👨',
  },
]
