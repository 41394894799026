import {
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-moment'
import annotationPlugin from 'chartjs-plugin-annotation'
import useWindowDimensions from 'components/responsive'
import { DbRichTrack } from 'generated/database.types'
import { Line } from 'react-chartjs-2'
import { timestampToMinute } from '../pages/Home'

export const Chart = (props: {
  todaysData: Array<DbRichTrack>
  isToday: boolean
}) => {
  const { width } = useWindowDimensions()

  const linecolor: Parameters<typeof ChartJS.register>[0] = {
    id: 'linecolor',
    afterLayout: (chart) => {
      let ctx = chart.ctx
      ctx.save()

      let yAxis = chart.scales.y
      let yThreshold = yAxis.getPixelForValue(51)
      let gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom)
      let offset = (1 / yAxis.bottom) * yThreshold

      gradient.addColorStop(offset, 'rgba(250, 126, 126, 0.5)')
      gradient.addColorStop(offset, 'rgba(53, 162, 235, 0.5)')
      chart.data.datasets[0].backgroundColor = gradient

      ctx.restore()
    },
  }

  ChartJS.register(
    linecolor,
    annotationPlugin,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
  )

  const options = {
    elements: {
      line: {
        lineTension: 0.3,
      },
    },
    plugins: {
      tooltip: {
        usePointStyle: true,
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || ''

            const calcLabel = (val: number): string | undefined => {
              if (typeof val !== 'number') {
                return undefined
              }
              if (val > 50) {
                return ` Sauna full, ${val - 50} waiting`
              }
              return ` ${val} visitors, ${50 - val} spots free`
            }

            if (context.parsed.y !== null) {
              label = calcLabel(Number(context.parsed.y))
            }
            return label
          },
          title: (context: any) => {
            let label = context[0].label.split(',')
            return label[2]
          },
        },
      },
      annotation: {
        annotations: props.isToday && [
          {
            type: 'line',
            borderColor: '#3a993a84',
            borderWidth: 4,
            scaleID: 'x',
            value: new Date(
              2022,
              0,
              1,
              0,
              new Date().getHours() < 10
                ? 600
                : timestampToMinute(new Date().getTime()) + 1,
            ),
          },
          // {
          //   type: 'line',
          //   borderColor: 'rgb(155, 46, 46)',
          //   borderWidth: 6,
          //   scaleID: 'y',
          //   value: 50,
          //   label: {
          //     display: true,
          //     content: 'Max capacity',
          //     position: 'start',
          //   },
          // },
          {
            type: 'box',
            xMin: new Date(2022, 0, 1, 0, 600),
            xMax: new Date(
              2022,
              0,
              1,
              0,
              new Date().getHours() < 10
                ? 600
                : timestampToMinute(new Date().getTime()),
            ),
            // label: {
            //   display: true,
            //   content: 'Todays data',
            //   position: 'start',
            // },
            borderColor: 'rgba(255, 255, 255, 0.114)',
            backgroundColor: 'rgba(255, 255, 255, 0.67)',
          },
          {
            type: 'box',
            xMin: new Date(
              2022,
              0,
              1,
              0,
              new Date().getHours() < 10
                ? 600
                : timestampToMinute(new Date().getTime()),
            ),
            xMax: new Date(2022, 0, 1, 0, 1380),
            borderColor: 'rgba(0, 0, 0, 0)',
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
        ],
      },
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    response: true,
    scales: {
      x: {
        grid: {
          display: true,
          color: 'rgba(228, 228, 228, 0.369)',
        },
        type: 'time',
        time: {
          unit: 'hour',
        },
      },
      y: {
        grid: {
          display: true,
          color: 'rgba(228, 228, 228, 0.369)',
        },
        stacked: true,
        min: 0,
        max: 80,
      },
    },
  }

  return (
    <Line
      style={{ width: width - 64 }}
      // @ts-ignore: Library type error
      options={options}
      data={{
        datasets: [
          {
            fill: true,
            pointRadius: 0,
            borderColor: 'rgba(57, 57, 57, 0.098)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            data: props.todaysData.map((v) => ({
              x: new Date(2022, 0, 1, 0, timestampToMinute(v.timestamp)),
              y: 50 - v.count,
            })),
          },
        ],
      }}
    />
  )
}
