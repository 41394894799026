import { DbRichTrack } from 'generated/database.types'
import { Home } from 'pages/Home'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRichRecords } from 'shared/actions/api.actions'
import { getRichDataSelector } from 'shared/selectors/selectors'

const timestampToMinute = (timestamp: number) =>
  new Date(timestamp).getHours() * 60 + new Date(timestamp).getMinutes()

const getTodaysData = (params: {
  allData: Array<DbRichTrack>
  isToday: boolean
}): Array<DbRichTrack> | undefined => {
  const { allData, isToday } = params

  if (allData.length === 0) {
    return undefined
  }

  const cleaned = allData.filter((v, idx) => v.count !== 99)

  if (cleaned.length === 0) {
    return undefined
  }

  const sorted = [...cleaned].sort(
    (a, b) => timestampToMinute(a.timestamp) - timestampToMinute(b.timestamp),
  )

  //live data and forecast
  if (isToday) {
    const liveData = sorted.filter(
      (v) => new Date(v.timestamp).getDate() === new Date().getDate(),
    )

    if (liveData.length === 0) {
      return sorted
    }

    const forecast = sorted.filter(
      (v) =>
        new Date(v.timestamp).getDate() !== new Date().getDate() &&
        timestampToMinute(v.timestamp) >=
          timestampToMinute(liveData[liveData.length - 1].timestamp),
    )

    const live = liveData[liveData.length - 1].count
    const forec = forecast[0] ? forecast[0].count : live

    const diff = Math.abs(forec - live)

    const adjustedForecast: Array<DbRichTrack> = forecast.map((v, idx) => {
      const change = diff - idx * (diff / 50)
      if ((change > 0 && diff <= 0) || (change < 0 && diff >= 0)) {
        return v
      }

      const sum =
        live > forec
          ? Math.floor(v.count + change)
          : Math.floor(v.count - change < -8 ? v.count : v.count - change)

      return { ...v, count: sum < -15 ? -15 : sum }
    })

    const res = liveData.concat(adjustedForecast)
    return res
  } else {
    return sorted
  }
}

export const HomeAsync = () => {
  const dispatch = useDispatch()

  const allData = useSelector(getRichDataSelector)
  //   const currData = useSelector(getCurrentRichDataSelector)

  useEffect(() => {
    dispatch(
      getRichRecords.call({
        query: {
          startDate: new Date().setHours(0, 0, 0, 0) - 1000 * 60 * 60 * 24 * 7,
          endDate: new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24,
        },
      }),
    )
    console.log(
      '🥷 Love building cool stuff? Apply at foronered.com, we are hiring.',
    )
  }, [dispatch])

  const [selectedDay, setSelectedDay] = useState<number>(new Date().getDay())

  const [todaysData, setTodaysData] = useState<Array<DbRichTrack> | undefined>()

  const [freeSpots, setFreeSpots] = useState<number>()
  const [lakeTemp, setLakeTemp] = useState<number>()

  useEffect(() => {
    const onlyTodaysData = getTodaysData({
      allData: allData[new Date().getDay()].recs,
      isToday: true,
    })

    if (onlyTodaysData) {
      const filtered = [...onlyTodaysData].filter(
        (v) => new Date(v.timestamp).getDate() === new Date().getDate(),
      )
      const sorted = filtered.sort((a, b) => a.timestamp - b.timestamp)
      if (sorted.length > 0) {
        setFreeSpots(sorted[sorted.length - 1].count)
        setLakeTemp(sorted[sorted.length - 1].lakeTemp)
      }
    }
  }, [allData])

  useEffect(() => {
    const today = new Date().getDay()

    const todaysData = getTodaysData({
      allData: allData[selectedDay].recs,
      isToday: selectedDay === today,
    })

    if (todaysData) {
      setTodaysData(todaysData)
    } else {
      setTodaysData(undefined)
    }
  }, [selectedDay, allData])

  //   const todaysData = [...allData][selectedDay].recs.filter(
  //     (v, idx) => new Date(v.timestamp).getDate() !== new Date().getDate(),
  //   )

  //   const liveData = [...allData][new Date().getDay()].recs.filter(
  //     (v) => new Date(v.timestamp).getDate() === new Date().getDate(),
  //   )

  //   todaysData.sort(
  //     (a, b) => timestampToMinute(a.timestamp) - timestampToMinute(b.timestamp),
  //   )

  if (!todaysData) {
    return <h3>Collecting data for forecast...</h3>
  }

  return (
    <Home
      todaysData={todaysData}
      freeSpots={freeSpots === undefined ? 0 : freeSpots > 0 ? freeSpots : 0}
      waitingList={
        freeSpots === undefined ? 0 : freeSpots > 0 ? 0 : Math.abs(freeSpots)
      }
      busierThanUsual={freeSpots > 0 ? 0 : Math.abs(freeSpots)}
      lakeTemp={lakeTemp === undefined ? 0 : lakeTemp}
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
    />
  )
}
